import fadedLogo from '../util/pictures/fadedLogo.jpg'
import pinkLogo from '../util/pictures/CourtneyStudioLogoPinkMargins1.jpg'
import missionImage from '../util/pictures/Mission.jpg'


const styleSheet = {
    indexSetup: {
        backgroundImage: `url(${pinkLogo})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "90%",
        padding: "75px",
    },
    pageSetup: {
        backgroundImage: `url(${fadedLogo})`,
        backgroundPosition: "center", /* Center the image */
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "90%",
        padding: "2rem",
    },
    NavBar: {
        backgroundColor: "none",
    },
    NavBrand: {
        fontFamily: "Saira_Extra_Condensed",
        fontSize: "1.8em",
        fontWeight: "500",
        fontStyle: "normal",
        fontStretch: 'condensed',
        letterSpacing: "0.125rem",
        lineHeight: ".9em",
        textTransform: "uppercase",
        color: "rgba(250,20,120,.6)",
    },
    NavLinks: {
        margin: "none",
        fontFamily: "OpenSans",
        fontWeight: "700",
        fontStyle: "normal",
        letterSpacing: ".03em",
        textTransform: "uppercase",
        fontSize: "0.65em",
    },
    AboutPage: {
        portrait: {
            paddingRight: "1em",
            paddingLeft: "1em",
            width: "40%",
            height: "auto",
            objectFit: "scale-down",
        },
        banner: {
            padding: "1em",
            width: "95%",
            objectFit: "scale-down",
        },
        textContainer: {
            paddingLeft: "1em",
            paddingRight: "1em",
            paddingTop: "1em",
            paddingBottom: "2em",
        },
        textContainerLeft: {
            paddingLeft: "1em",
            paddingRight: "1em",
            paddingTop: "1em",
            paddingBottom: "2em",
        },
        text: {
            fontFamily: "OpenSans",
            fontWeight: "400",
            fontStyle: "normal",
            fontSize: "0.9em",
            letterSpacing: ".02em",
            lineHeight: "2.4em",
            textTransform: "none",

        },
    },
    titles : {
        fontFamily: "Saira_Extra_Condensed",
        fontWeight: "500",
        fontStyle: "normal",
        fontStretch: 'condensed',
        fontSize: "38px",
        letterSpacing: ".075em",
        lineHeight: "1.2em",
        textTransform: "uppercase",
        paddingTop: "1.5em",
        paddingBottom: "1.5em",
        textAlign: 'center',
    },
    h1 : {

    },
    h2 : {
        fontFamily: 'Saira_Extra_Condensed',
        fontStretch: 'condensed',
        fontWeight: "800",
        fontStyle: "normal",
        fontSize: "24px",
        letterSpacing: ".075em",
        lineHeight: "1.4em",
        textTransform: "uppercase",
        textAlign: 'center',
        paddingTop: "1.5em",
        paddingBottom: "1.5em",
    },
    h3 : {
        fontFamily: 'Saira_Extra_Condensed',
        fontStretch: 'condensed',
        fontWeight: "500",
        fontStyle: "normal",
        fontSize: "24px",
        letterSpacing: ".075em",
        lineHeight: "1.4em",
        textTransform: "uppercase",
        textAlign: 'center',
        paddingTop: "1.5em",
        paddingBottom: "1.5em",
    },
    p : {
        fontFamily: "OpenSans",
        fontWeight: "400",
        fontStyle: "normal",
        fontSize: "14px",
        letterSpacing: ".02em",
        lineHeight: "2.4em",
        textTransform: "none",
    },
    constulting: {
        booksImage: {
            paddingRight: "5em",
            paddingLeft: "5em",
            width: "50%",
            height: "auto",
            objectFit: "scale-down",
            verticalAlign: "top",
            display: 'block',
        },
        section4Custom: {
            fontFamily: "OpenSans",
            fontWeight: "400",
            fontStyle: "normal",
            fontSize: "14px",
            letterSpacing: ".02em",
            lineHeight: "2.4em",
            textTransform: "none",
            textAlign: "center",
        }
    },
    appointments: {
        h2 : {
            fontFamily: 'Saira_Extra_Condensed',
            fontStretch: 'condensed',
            fontWeight: "500",
            fontStyle: "normal",
            fontSize: "40px",
            letterSpacing: ".075em",
            lineHeight: "1.4em",
            textTransform: "uppercase",
            textAlign: 'center',
            paddingTop: "1em",
            paddingBottom: "1em",
        },
        h3 : {
            fontFamily: 'Saira_Extra_Condensed',
            fontStretch: 'condensed',
            fontWeight: "600",
            fontStyle: "normal",
            fontSize: "24px",
            letterSpacing: ".075em",
            lineHeight: "1.4em",
            textTransform: "uppercase",
            textAlign: 'center',
            paddingTop: "1em",
            paddingBottom: "1.5em",
        },
        p : {
            fontFamily: "OpenSans",
            fontWeight: "400",
            fontStyle: "normal",
            fontSize: "14px",
            letterSpacing: ".02em",
            lineHeight: "2.4em",
            textTransform: "none",
            textAlign: 'center',
        },
    },
    MissionComponent : {
        background : {
            backgroundImage: `url(${missionImage})`,
            backgroundPosition: "center", /* Center the image */
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundSize: "100%",
            padding: "75px",
            paddingTop: '12em',
            paddingBottom: '12em',
        },
        h1 : {
            fontFamily: 'Saira_Extra_Condensed',
            fontWeight: '500',
            fontStretch: 'condensed',
            fontStyle: 'normal',
            fontSize: '38px',
            letterSpacing: '.075em',
            lineHeight: '1.2em',
            textTransform: 'uppercase',
            textAlign: 'center',
            paddingLeft: '7%',
            paddingRight: '7%',
        },
        h3 : {
            fontFamily: 'Bebas_Neue',
            //fontWeight: '600',
            fontStyle: 'normal',
            fontSize: '14px',
            letterSpacing: '.2em',
            lineHeight: '1.8em',
            textTransform: 'uppercase',
            textAlign: 'center',
        },
    },
    pageCard: {
        card: {
            paddingTop: '2em',
            paddingBottom: '2em',
        },
        div: {
            
        },
        img : {
            objectFit: 'cover',
            height: '50em',
        },
        button: {
            borderRadius: '3px',
            color: '#000',
            borderWidth: '2px',
            borderStyle: 'solid',
            backgroundColor: 'transparent',
            borderColor: '#000',
            fontFamily: 'Bebas_Neue',
            fontSize: '16px',
            //fontWeight: '600',
            fontStyle: 'normal',
            textTransform: 'uppercase',
            letterSpacing: '.2em',
            textAlign: 'center',
            textDecoration: 'none',
            cursor: 'pointer',
            padding: '21px 34px',
            hover : {
                color: '#FFF',
                backgroundColor: '#000',
            },
        },
        h2 : {
            fontFamily: 'Saira_Extra_Condensed',
            fontStretch: 'condensed',
            fontWeight: "600",
            fontStyle: "normal",
            fontSize: "24px",
            letterSpacing: ".075em",
            lineHeight: "1.4em",
            textTransform: "uppercase",
        },
        p : {
            marginBlockStart: '1em',
            marginBlockEnd: '1em',
            fontFamily: "OpenSans",
            fontWeight: "400",
            fontStyle: "normal",
            fontSize: "14px",
            letterSpacing: ".02em",
            lineHeight: "2.4em",
            textTransform: "none",
            color: 'rgba(82,82,82,.78)',
        },
    },
    LandingPage: {
        img: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '90%',
        },
    },
    blurb: {
        paddingTop: '2em',
        paddingBottom: '2em',
        h2: {
            fontFamily: 'Saira_Extra_Condensed',
            fontStretch: 'condensed',
            fontWeight: "800",
            fontStyle: "normal",
            fontSize: "24px",
            letterSpacing: ".075em",
            lineHeight: "1.4em",
            textTransform: "uppercase",
            textAlign: 'center',
        },
    },
    pictureBanner: {
        backgroundImage: `url(${pinkLogo})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "90%",
        padding: "75px",
        height: '500px',
    },
    bookNow: {
        h2 : {
            fontFamily: 'Saira_Extra_Condensed',
            fontStretch: 'condensed',
            fontWeight: "700",
            fontStyle: "normal",
            fontSize: "38px",
            letterSpacing: ".075em",
            lineHeight: "1.4em",
            textTransform: "uppercase",
            textAlign: 'center',
            paddingTop: "1em",
        },
        h3 : {
            fontFamily: 'Saira_Extra_Condensed',
            fontStretch: 'condensed',
            fontWeight: "600",
            fontStyle: "normal",
            fontSize: "24px",
            letterSpacing: ".075em",
            lineHeight: "1.4em",
            textTransform: "uppercase",
            textAlign: 'center',
            paddingTop: "1em",
        },
        p : {
            marginBlockStart: '1em',
            marginBlockEnd: '1em',
            fontFamily: "OpenSans",
            fontWeight: "400",
            fontStyle: "normal",
            fontSize: "14px",
            letterSpacing: ".02em",
            lineHeight: "2.4em",
            textTransform: "none",
            color: 'rgba(82,82,82,.78)',
            textAlign: 'center',
            paddingTop: '1em',
            paddingBottom: '1em',
        },
        div : {
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
        },
        button: {
            borderRadius: '3px',
            color: '#000',
            borderWidth: '2px',
            borderStyle: 'solid',
            backgroundColor: 'transparent',
            borderColor: '#000',
            fontFamily: 'Bebas_Neue',
            fontSize: '16px',
            //fontWeight: '600',
            fontStyle: 'normal',
            textTransform: 'uppercase',
            letterSpacing: '.2em',
            textAlign: 'center',
            textDecoration: 'none',
            cursor: 'pointer',
            padding: '21px 34px',
        },
    },
    footer : {
        container: {
            display: 'flex',
            justifyContent: 'center',
        },
        a: {
            textAlign: 'center',
            color: 'rgba(82, 82, 82, .78)',
            padding: '0.5em',
            margin: "none",
            fontFamily: "Bebas_Neue",
            //fontWeight: "900",
            fontStyle: "normal",
            letterSpacing: ".2em",
            textTransform: "uppercase",
            fontSize: "12px",
            textDecoration: 'none',
        },
        h1: {
        },
        h2Container: {
            display: 'flex',
            justifyContent: 'center',
        },
        h2: {
            marginBottom: '1em',
            paddingTop: '3em',
            fontFamily: 'Saira_Extra_Condensed',
            fontStretch: 'condensed',
            fontWeight: "700",
            fontStyle: "normal",
            fontSize: "24px",
            letterSpacing: ".075em",
            lineHeight: "1.4em",
            textTransform: "uppercase",
            textAlign: 'center',
        },
        links: {

        },
        copyrightInfo: {
            fontFamily: 'Bebas_Neue',
            //fontWeight: '600',
            fontStyle: 'normal',
            fontSize: '14px',
            letterSpacing: '.2em',
            lineHeight: '1.8em',
            textTransform: 'uppercase',
            textAlign: 'center',
            paddingTop: '2em',
        },
        faContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '3em',
        },
        fa: {
            fontSize: '1.3em',
            width: '15px',
            textAlign: 'center',
            textDecoration: 'none',
            color: '#000',
            margin: '0.4em',
        },
        span: {
            display: 'block',
        },
    },
    FAQ: {
        h2 : {
            fontFamily: 'Saira_Extra_Condensed',
            fontStretch: 'condensed',
            fontWeight: "700",
            fontStyle: "normal",
            fontSize: "24px",
            letterSpacing: ".075em",
            lineHeight: "1.4em",
            textTransform: "uppercase",
            textAlign: 'left',
            paddingTop: "1.5em",
            paddingBottom: "1.5em",
            paddingLeft: '2rem',
        },
        h3 : {
            fontFamily: 'Saira_Extra_Condensed',
            fontStretch: 'condensed',
            fontWeight: "500",
            fontStyle: "normal",
            fontSize: "24px",
            letterSpacing: ".075em",
            lineHeight: "1.4em",
            textTransform: "uppercase",
            textAlign: 'left',
            paddingTop: "1.5rem",
            paddingBottom: "1.5rem",
            paddingLeft: "2rem",
        },
        p : {
            marginBlockStart: '1rem',
            marginBlockEnd: '1rem',
            fontFamily: "OpenSans",
            fontWeight: "400",
            fontStyle: "normal",
            fontSize: "14px",
            letterSpacing: ".02rem",
            lineHeight: "2.4rem",
            textTransform: "none",
            color: 'rgba(82,82,82,.78)',
            paddingRight:'1rem',
        },
        flex: {
            display: 'flex',
        },
        flexH2: {
            display: 'flex',
            width: '30%',
        },
        flexP: {
            display: 'flex',
            width: '70%'
        },
        seperatorLine: {
            borderTop: '2px solid RGBA(83,82,82,0.5)',
            margin: '1.4rem',
        },},
    mobile: {
        indexSetup: {
            backgroundImage: `url(${pinkLogo})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundSize: "90%",
            padding: "75px",
        },
        pageSetup: {
            backgroundImage: `url(${fadedLogo})`,
            backgroundPosition: "center", /* Center the image */
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundSize: "100%",
            margin: '15px',
        },
        NavBar: {
            backgroundColor: "none",
        },
        NavBrand: {
            fontFamily: "Saira_Extra_Condensed",
            fontSize:"100%",
            fontWeight: "500",
            fontStyle: "normal",
            fontStretch: 'condensed',
            letterSpacing: "0.125rem",
            lineHeight: ".5rem",
            textTransform: "uppercase",
            color: "rgba(250,20,120,.6)",
            textAlign: "center",
            margin: "0",
            padding: "0",

        },
        NavLinks: {
            margin: "none",
            fontFamily: "OpenSans",
            fontWeight: "700",
            fontStyle: "normal",
            letterSpacing: ".03em",
            textTransform: "uppercase",
            fontSize: "0.65em",
        },
        AboutPage: {
            portrait: {
                paddingRight: "1em",
                paddingLeft: "1em",
                width: "40%",
                height: "auto",
                objectFit: "scale-down",
            },
            banner: {
                padding: "1em",
                width: "95%",
                objectFit: "scale-down",
            },
            textContainer: {
                paddingLeft: "1em",
                paddingRight: "1em",
                paddingTop: "1em",
                paddingBottom: "2em",
            },
            textContainerLeft: {
                paddingLeft: "1em",
                paddingRight: "1em",
                paddingTop: "1em",
                paddingBottom: "2em",
            },
            text: {
                fontFamily: "OpenSans",
                fontWeight: "400",
                fontStyle: "normal",
                fontSize: "0.9em",
                letterSpacing: ".02em",
                lineHeight: "2.4em",
                textTransform: "none",
    
            },
        },
        titles : {
            fontFamily: "Saira_Extra_Condensed",
            fontWeight: "500",
            fontStyle: "normal",
            fontStretch: 'condensed',
            fontSize: "38px",
            letterSpacing: ".075em",
            lineHeight: "1.2em",
            textTransform: "uppercase",
            paddingTop: "1.5em",
            paddingBottom: "1.5em",
            textAlign: 'center',
        },
        h1 : {
    
        },
        h2 : {
            fontFamily: 'Saira_Extra_Condensed',
            fontStretch: 'condensed',
            fontWeight: "800",
            fontStyle: "normal",
            fontSize: "24px",
            letterSpacing: ".075em",
            lineHeight: "1.4em",
            textTransform: "uppercase",
            textAlign: 'center',
            paddingTop: "1.5em",
            paddingBottom: "1.5em",
        },
        h3 : {
            fontFamily: 'Saira_Extra_Condensed',
            fontStretch: 'condensed',
            fontWeight: "500",
            fontStyle: "normal",
            fontSize: "24px",
            letterSpacing: ".075em",
            lineHeight: "1.4em",
            textTransform: "uppercase",
            textAlign: 'center',
            paddingTop: "1.5em",
            paddingBottom: "1.5em",
        },
        p : {
            fontFamily: "OpenSans",
            fontWeight: "400",
            fontStyle: "normal",
            fontSize: "14px",
            letterSpacing: ".02em",
            lineHeight: "2.4em",
            textTransform: "none",
        },
        constulting: {
            booksImage: {
                paddingRight: "5em",
                paddingLeft: "5em",
                width: "50%",
                height: "auto",
                objectFit: "scale-down",
                verticalAlign: "top",
                display: 'block',
            },
            section4Custom: {
                fontFamily: "OpenSans",
                fontWeight: "400",
                fontStyle: "normal",
                fontSize: "14px",
                letterSpacing: ".02em",
                lineHeight: "2.4em",
                textTransform: "none",
                textAlign: "center",
            }
        },
        appointments: {
            h2 : {
                fontFamily: 'Saira_Extra_Condensed',
                fontStretch: 'condensed',
                fontWeight: "500",
                fontStyle: "normal",
                fontSize: "40px",
                letterSpacing: ".075em",
                lineHeight: "1.4em",
                textTransform: "uppercase",
                textAlign: 'center',
                paddingTop: "1em",
                paddingBottom: "1em",
            },
            h3 : {
                fontFamily: 'Saira_Extra_Condensed',
                fontStretch: 'condensed',
                fontWeight: "600",
                fontStyle: "normal",
                fontSize: "24px",
                letterSpacing: ".075em",
                lineHeight: "1.4em",
                textTransform: "uppercase",
                textAlign: 'center',
                paddingTop: "1em",
                paddingBottom: "1.5em",
            },
            p : {
                fontFamily: "OpenSans",
                fontWeight: "400",
                fontStyle: "normal",
                fontSize: "14px",
                letterSpacing: ".02em",
                lineHeight: "2.4em",
                textTransform: "none",
                textAlign: 'center',
            },
        },
        MissionComponent : {
            background : {
                backgroundImage: `url(${missionImage})`,
                backgroundPosition: "cover", /* Center the image */
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "90%",
            },
            h1 : {
                fontFamily: 'Saira_Extra_Condensed',
                fontWeight: '500',
                fontStretch: 'condensed',
                fontStyle: 'normal',
                fontSize: '1.5rem',
                letterSpacing: '.075em',
                lineHeight: '1.2em',
                textTransform: 'uppercase',
                textAlign: 'center',
                paddingLeft: '7%',
                paddingRight: '7%',
            },
            h3 : {
                fontFamily: 'Bebas_Neue',
                //fontWeight: '600',
                fontStyle: 'normal',
                fontSize: '2.2rem',
                letterSpacing: '.2em',
                lineHeight: '1.8em',
                textTransform: 'uppercase',
                textAlign: 'center',
            },
        },
        pageCard: {
            card: {
                paddingTop: '2em',
                paddingBottom: '2em',
            },
            div: {
            },
            img : {
                display: 'block',
                width: '95%',
                marginLeft: 'auto',
                marginRight: 'auto',
                borderRadius: '8px',
            },
            button: {
                display: 'block',
                borderRadius: '3px',
                color: '#000',
                borderWidth: '2px',
                borderStyle: 'solid',
                backgroundColor: 'transparent',
                borderColor: '#000',
                fontFamily: 'Bebas_Neue',
                fontSize: '16px',
                //fontWeight: '600',
                fontStyle: 'normal',
                textTransform: 'uppercase',
                letterSpacing: '.2em',
                textAlign: 'center',
                textDecoration: 'none',
                cursor: 'pointer',
                padding: 'auto',
                margin: 'auto',
                hover : {
                    color: '#FFF',
                    backgroundColor: '#000',
                },
            },
            h2 : {
                fontFamily: 'Saira_Extra_Condensed',
                fontStretch: 'condensed',
                fontWeight: "600",
                fontStyle: "normal",
                fontSize: "24px",
                letterSpacing: ".075em",
                lineHeight: "1.4em",
                textTransform: "uppercase",
            },
            p : {
                marginBlockStart: '1em',
                marginBlockEnd: '1em',
                fontFamily: "OpenSans",
                fontWeight: "400",
                fontStyle: "normal",
                fontSize: "14px",
                letterSpacing: ".02em",
                lineHeight: "2.4em",
                textTransform: "none",
                color: 'rgba(82,82,82,.78)',
            },
        },
        LandingPage: {
            img: {
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '90%',
            },
        },
        blurb: {
            paddingTop: '2em',
            paddingBottom: '2em',
            h2: {
                fontFamily: 'Saira_Extra_Condensed',
                fontStretch: 'condensed',
                fontWeight: "800",
                fontStyle: "normal",
                fontSize: "24px",
                letterSpacing: ".075em",
                lineHeight: "1.4em",
                textTransform: "uppercase",
                textAlign: 'center',
            },
        },
        pictureBanner: {
            
            img: {
               maxWidth: "100%",
               height: "auto",
               display: "block",
               marginLeft: "auto",
               marginRight: "auto", 
            
            },
        },
        bookNow: {
            h2 : {
                fontFamily: 'Saira_Extra_Condensed',
                fontStretch: 'condensed',
                fontWeight: "700",
                fontStyle: "normal",
                fontSize: "38px",
                letterSpacing: ".075em",
                lineHeight: "1.4em",
                textTransform: "uppercase",
                textAlign: 'center',
                paddingTop: "1em",
            },
            h3 : {
                fontFamily: 'Saira_Extra_Condensed',
                fontStretch: 'condensed',
                fontWeight: "600",
                fontStyle: "normal",
                fontSize: "24px",
                letterSpacing: ".075em",
                lineHeight: "1.4em",
                textTransform: "uppercase",
                textAlign: 'center',
                paddingTop: "1em",
            },
            p : {
                marginBlockStart: '1em',
                marginBlockEnd: '1em',
                fontFamily: "OpenSans",
                fontWeight: "400",
                fontStyle: "normal",
                fontSize: "14px",
                letterSpacing: ".02em",
                lineHeight: "2.4em",
                textTransform: "none",
                color: 'rgba(82,82,82,.78)',
                textAlign: 'center',
                paddingTop: '1em',
                paddingBottom: '1em',
            },
            div : {
                display: 'flex',
                marginLeft: 'auto',
                marginRight: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
            },
            button: {
                borderRadius: '3px',
                color: '#000',
                borderWidth: '2px',
                borderStyle: 'solid',
                backgroundColor: 'transparent',
                borderColor: '#000',
                fontFamily: 'Bebas_Neue',
                fontSize: '16px',
                //fontWeight: '600',
                fontStyle: 'normal',
                textTransform: 'uppercase',
                letterSpacing: '.2em',
                textAlign: 'center',
                textDecoration: 'none',
                cursor: 'pointer',
                padding: '21px 34px',
            },
        },
        footer : {
            container: {
                display: 'flex',
                justifyContent: 'center',
            },
            a: {
                textAlign: 'center',
                color: 'rgba(82, 82, 82, .78)',
                padding: '0.5em',
                margin: "none",
                fontFamily: "Bebas_Neue",
                //fontWeight: "900",
                fontStyle: "normal",
                letterSpacing: ".2em",
                textTransform: "uppercase",
                fontSize: "12px",
                textDecoration: 'none',
            },
            h1: {
            },
            h2Container: {
                display: 'flex',
                justifyContent: 'center',
            },
            h2: {
                marginBottom: '1em',
                paddingTop: '3em',
                fontFamily: 'Saira_Extra_Condensed',
                fontStretch: 'condensed',
                fontWeight: "700",
                fontStyle: "normal",
                fontSize: "24px",
                letterSpacing: ".075em",
                lineHeight: "1.4em",
                textTransform: "uppercase",
                textAlign: 'center',
            },
            links: {
    
            },
            copyrightInfo: {
                fontFamily: 'Bebas_Neue',
                //fontWeight: '600',
                fontStyle: 'normal',
                fontSize: '14px',
                letterSpacing: '.2em',
                lineHeight: '1.8em',
                textTransform: 'uppercase',
                textAlign: 'center',
                paddingTop: '2em',
            },
            faContainer: {
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '3em',
            },
            fa: {
                fontSize: '1.3em',
                width: '15px',
                textAlign: 'center',
                textDecoration: 'none',
                color: '#000',
                margin: '0.4em',
            },
            span: {
                display: 'block',
            },
        },
        FAQ: {
            h2 : {
                fontFamily: 'Saira_Extra_Condensed',
                fontStretch: 'condensed',
                fontWeight: "700",
                fontStyle: "normal",
                fontSize: "24px",
                letterSpacing: ".075em",
                lineHeight: "1.4em",
                textTransform: "uppercase",
                textAlign: 'center',
                paddingTop: "1.5em",
                paddingBottom: "1.5em",
                paddingLeft: '2rem',
            },
            h3 : {
                fontFamily: 'Saira_Extra_Condensed',
                fontStretch: 'condensed',
                fontWeight: "500",
                fontStyle: "normal",
                fontSize: "24px",
                letterSpacing: ".075em",
                lineHeight: "1.4em",
                textTransform: "uppercase",
                textAlign: 'left',
                paddingTop: "1.5rem",
                paddingBottom: "1.5rem",
                paddingLeft: "2rem",
            },
            p : {
                marginBlockStart: '1rem',
                marginBlockEnd: '1rem',
                fontFamily: "OpenSans",
                fontWeight: "400",
                fontStyle: "normal",
                fontSize: "14px",
                letterSpacing: ".02rem",
                lineHeight: "2.4rem",
                textTransform: "none",
                color: 'rgba(82,82,82,.78)',
                paddingRight:'1rem',
            },
            flex: {
                display: 'block',
            },
            flexH2: {
            },
            flexP: {   
            },
            seperatorLine: {
                borderTop: '2px solid RGBA(83,82,82,0.5)',
                margin: '1.4rem',
            }
        },
    },
}

export default styleSheet
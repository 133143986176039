import React from 'react'
import { pageText } from '../../Information/pageText'
import styleSheet from '../../styles/styleSheet'

const AppointmentsLayout = () => {
    return (
        <div>
            <h2 style={styleSheet.appointments.h2}>{pageText.appointments.topText}</h2>

            <p style={styleSheet.appointments.p}>{pageText.appointments.middleText}</p>

            <h3 style={styleSheet.appointments.h3}>{pageText.appointments.bottomText}</h3>
            
        </div>
    )
}

export default AppointmentsLayout

import React from 'react'
import { navLinks } from '../../Information/pageLinks'
import styleSheet from '../../styles/styleSheet'

import '../../util/styles/AvailableFonts.css'

const NavBar = (props) => {
    return (
        <div className="">
            <nav className="navbar navbar-expand navbar-light mt-md-5 pt-md-5" style={styleSheet.NavBar}>
                <div className="col">
                <div className="text-center">
                <a className="navbar-brand col-12" href="/#/"><div style={styleSheet.NavBrand}>The Courtney Studio</div></a>
                <button className="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                </div>
                
            <div className="w-100"></div>
                <div className="d-flex justify-content-center">
                <div className="row">
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup" >
                <div className="navbar-nav" style={styleSheet.NavLinks}>
                {navLinks.map(item => {
                       return (props.thisPage===item.name ?  <a className="nav-link active" href={`${item.hash}${item.link}`}>{item.name}<span className="sr-only">(current)</span></a> : <a className="nav-link" href={`${item.hash}${item.link}`}>{item.name}</a>);
                    })}
            </div></div></div></div>
                </div>
            </nav>
            
            
        </div>
    )
}

export default NavBar

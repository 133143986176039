import React from 'react'
import styleSheet from '../../styles/styleSheet'

const PageCard = props => {
    function onMouseIn(e) {
        e.target.style.background = 'black';
        e.target.style.color = 'white';
    }
    function onMouseOut(e) {
            e.target.style.background = 'white';
            e.target.style.color = 'black';
    }
    return (
        <div className={`col-md-6 col-12 order-${props.order}`} style={styleSheet.pageCard.card}>
            <img src={props.page.frontPageImage} className="card-img-top" alt={props.page.pageTitle} style={props.device === "mobile" ? styleSheet.mobile.pageCard.img : styleSheet.pageCard.img}/>
            <div className="card-body">
                <h2 style={styleSheet.pageCard.h2} className="card-title">{props.page.pageTitle}</h2>
                {props.page.frontPageText.map(p => {
                    return <p key={p} style={styleSheet.pageCard.p} className="card-text">{p}</p>
                })}
                {props.device === "mobile" ? <br/> : null}
                <div className={props.device === "mobile" ? '' :'row'}>
                <div className={props.device === "mobile" ? '' :'col-6'} style={styleSheet.pageCard.div}>
                <a onMouseEnter={onMouseIn} onMouseLeave={onMouseOut} href={`${props.link.hash}${props.link.link}`} className="btn" style={props.device === "mobile" ? styleSheet.mobile.pageCard.button : styleSheet.pageCard.button}>{props.link.name}</a>
                </div>
                {props.device === "mobile" ? <br/> : null}
                <div className={props.device === "mobile" ? '' :'col-6'} style={styleSheet.pageCard.div}>
                {props.link2 ? <a onMouseEnter={onMouseIn} onMouseLeave={onMouseOut} href={`${props.link2.link}`} className="btn" style={props.device === "mobile" ? styleSheet.mobile.pageCard.button : styleSheet.pageCard.button}>{props.link2.name}</a> : null}
                </div>
                </div>
            </div>
        </div>
    )
}



export default PageCard

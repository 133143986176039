import React from 'react'
import { HashRouter as Router, Route, Switch, Redirect} from 'react-router-dom' 
import {navLinks, otherPages} from '../../Information/pageLinks'
import Index from '../../Pages'
import HomePage from '../../Pages/home'
import ScrollToTop from '../ScrollToTop/ScrollToTop'
import { ViewportProvider } from '../ViewPort/ViewportProvider'

function App() {
  return (
    <div className="App">
      <ViewportProvider>
      <Router>
      <ScrollToTop />
            <Switch>
            {navLinks.map(item => {
                        return <Route exact path={item.link} component={item.pageName} />;
                    })}

            {otherPages.map(item => {
                        return <Route exact path={item.link} component={item.pageName} />;
                    })}
              <Route exact path='/' component={HomePage} />
              <Redirect to={navLinks[0].link} />
            </Switch>
        </Router>
        </ViewportProvider>
    </div>
  );
}

export default App;
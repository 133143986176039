import React from 'react'
import { businessInfo } from '../../Information/pageText'
import styleSheet from '../../styles/styleSheet'

const Blurb = () => {
    return (
        <div style={styleSheet.blurb}>
            <h2 style={styleSheet.blurb.h2}>{businessInfo.blurb}</h2>           
        </div>
    )
}

export default Blurb

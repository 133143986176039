import React from 'react'
import AppointmentsLayout from '../components/Appointments/AppointmentsLayout'
import NavBar from '../components/Nav/NavBar'
import styleSheet from '../styles/styleSheet'
import Footer from '../components/Footer/Footer'
import { useViewport } from '../components/ViewPort/ViewportProvider'
import MobileNavBar from '../components/Nav/MobileNavBar'

export const Appointments = () => {
    const { width } = useViewport();
    const breakpoint = 567;

    return (
        width < breakpoint ? 
        <div style={styleSheet.mobile.pageSetup} >
            <MobileNavBar thisPage="Appointments" />
            <AppointmentsLayout />
            <Footer />
        </div>
        :
        <div style={styleSheet.pageSetup} >
            <NavBar thisPage="Appointments" />
            <AppointmentsLayout />
            <Footer />
        </div>
    )
}

export default Appointments
import React from 'react'
import NavBar from '../components/Nav/NavBar'
import AboutLayout from '../components/About/AboutLayout'
import styleSheet from '../styles/styleSheet'
import Footer from '../components/Footer/Footer'
import MobileNavBar from '../components/Nav/MobileNavBar'
import { useViewport } from '../components/ViewPort/ViewportProvider'

const About = () => {
    const { width } = useViewport();
    const breakpoint = 567;

    return (
        width < breakpoint ? 
        
        <div style={styleSheet.mobile.pageSetup} >
            <MobileNavBar thisPage="About Courtney"/>
            <AboutLayout device="mobile"/>
            <Footer />
        </div>
        :
        <div style={styleSheet.pageSetup} >
            <NavBar thisPage="About Courtney"/>
            <AboutLayout device="desktop"/>
            <Footer />
        </div>
    )
}

export default About

import React from 'react'
import { businessInfo, pageText } from '../../Information/pageText'
import productInfo from '../../Information/productInfo'
import styleSheet from '../../styles/styleSheet'

const FAQ = (props) => {
    return (
        props.device === "mobile" ?
        <div>
            
            <h1 style={styleSheet.mobile.titles}>{pageText.FAQ.pageTitle}</h1>

            <div style={styleSheet.mobile.FAQ.seperatorLine}></div>

            <div style={styleSheet.mobile.FAQ.flex}>
            <div style={styleSheet.mobile.FAQ.flexH2}>
            <h2 style={styleSheet.mobile.FAQ.h2}>{businessInfo.products.oneOnOneStrategy.name2}</h2>
            </div><div style={styleSheet.mobile.FAQ.flexP}><ul>
                {productInfo.oneOnOneStrategy.description.map((item, index) => (
                        <li style={styleSheet.mobile.FAQ.p} key={index}>{item}</li>
                ))}
            </ul>
            </div>
            </div>

          <div style={styleSheet.mobile.FAQ.seperatorLine}></div>  
            
            <div style={styleSheet.mobile.FAQ.flex}>
            <div style={styleSheet.mobile.FAQ.flexH2}>
            <h2 style={styleSheet.mobile.FAQ.h2}>{businessInfo.products.selfTapeSessions.name}</h2>
            </div>
            <div style={styleSheet.mobile.FAQ.flexP}>
            <ul>
                {productInfo.selfTapeSessions.description.map((item, index) => (
                        <li style={styleSheet.mobile.FAQ.p} key={index}>{item}</li>
                ))}
            </ul>
            </div>
            </div>
            

            <div style={styleSheet.mobile.FAQ.flex}>
            <div style={styleSheet.mobile.FAQ.flexH2}>
            <h3 style={styleSheet.mobile.FAQ.h3}>COST of Self-Tape Services</h3>
            </div>
            <div style={styleSheet.mobile.FAQ.flexP}>
            <ul>
                {productInfo.selfTapeSessions.costBreakdown.map((item, index) => (
                        <li style={styleSheet.mobile.FAQ.p} key={index}>{item}</li>
                ))}
            </ul>
            </div>
            </div>

            <div style={styleSheet.mobile.FAQ.flex}>
            <div style={styleSheet.mobile.FAQ.flexH2}>
            <h3 style={styleSheet.mobile.FAQ.h3}>COST of Self-Tape Add Ons</h3>
            </div>
            <div style={styleSheet.mobile.FAQ.flexP}>
            <ul>
                {productInfo.selfTapeSessions.addOns.map((item, index) => (
                        <li style={styleSheet.mobile.FAQ.p} key={index}>{item}</li>
                ))}
            </ul>
            </div>
            </div>
            <div></div>
            
        </div>
        :
        <div>
            
            <h1 style={styleSheet.titles}>{pageText.FAQ.pageTitle}</h1>

            <div style={styleSheet.FAQ.seperatorLine}></div>

            <div style={styleSheet.FAQ.flex}>
            <div style={styleSheet.FAQ.flexH2}>
            <h2 style={styleSheet.FAQ.h2}>{businessInfo.products.oneOnOneStrategy.name2}</h2>
            </div><div style={styleSheet.FAQ.flexP}><ul>
                {productInfo.oneOnOneStrategy.description.map((item, index) => (
                        <li style={styleSheet.FAQ.p} key={index}>{item}</li>
                ))}
            </ul>
            </div>
            </div>

          <div style={styleSheet.FAQ.seperatorLine}></div>  
            
            <div style={styleSheet.FAQ.flex}>
            <div style={styleSheet.FAQ.flexH2}>
            <h2 style={styleSheet.FAQ.h2}>{businessInfo.products.selfTapeSessions.name}</h2>
            </div>
            <div style={styleSheet.FAQ.flexP}>
            <ul>
                {productInfo.selfTapeSessions.description.map((item, index) => (
                        <li style={styleSheet.FAQ.p} key={index}>{item}</li>
                ))}
            </ul>
            </div>
            </div>
            

            <div style={styleSheet.FAQ.flex}>
            <div style={styleSheet.FAQ.flexH2}>
            <h3 style={styleSheet.FAQ.h3}>COST of Self-Tape Services</h3>
            </div>
            <div style={styleSheet.FAQ.flexP}>
            <ul>
                {productInfo.selfTapeSessions.costBreakdown.map((item, index) => (
                        <li style={styleSheet.FAQ.p} key={index}>{item}</li>
                ))}
            </ul>
            </div>
            </div>

            <div style={styleSheet.FAQ.flex}>
            <div style={styleSheet.FAQ.flexH2}>
            <h3 style={styleSheet.FAQ.h3}>COST of Self-Tape Add Ons</h3>
            </div>
            <div style={styleSheet.FAQ.flexP}>
            <ul>
                {productInfo.selfTapeSessions.addOns.map((item, index) => (
                        <li style={styleSheet.FAQ.p} key={index}>{item}</li>
                ))}
            </ul>
            </div>
            </div>
            <div></div>
            
        </div>
    )
}

export default FAQ

import {businessInfo} from './pageText'

const productInfo = {
    oneOnOneStrategy : {
        product: businessInfo.products.oneOnOneStrategy,
        description: [
            `${businessInfo.products.oneOnOneStrategy.name2} are up to ${businessInfo.products.oneOnOneStrategy.time} Minuites long and ${businessInfo.products.oneOnOneStrategy.cost} per session.`,
            `The session must be paid for in full at the time of scheduling.`,
            `If you must reschedule, payment for the original appointment will carry over to the newly scheduled time.`,
            `If you must cancel, a full refund will be made up to 24 hours before the originally scheduled appointment. If the appointment is cancelled is within the 24 hour window, a 50% refund will be made.`
        ],
    },
    selfTapeSessions: {
        product: businessInfo.products.selfTapeSessions,
        description: [
            `The session must be paid for in full at the time of scheduling.`,
            `If you must reschedule, payment for the original appointment will carry over to the newly scheduled time.`,
            `If you must cancel, a full refund will be made if cancelled more than 24 hours before the originally scheduled appointment. If the appointment is cancelled is within the 24 hour window, a 50% refund will be made.`,
            `Cancellations in less than 2 hours will incur a $${businessInfo.products.selfTapeSessions.cancellationFee} cancellation fee. You will receive the partial refund stated above minus the cancellation fee.`,
        ],
        costBreakdown: [
            `$${businessInfo.products.selfTapeSessions.cost} for ${businessInfo.products.selfTapeSessions.time} minute session -- 0-1 Liners only!`,
            `$${businessInfo.products.selfTapeSessions.cost * 2} for ${businessInfo.products.selfTapeSessions.time * 2} minute session`,
            `$${businessInfo.products.selfTapeSessions.cost * 3} for ${businessInfo.products.selfTapeSessions.time * 3} minute session*`,
            `$${businessInfo.products.selfTapeSessions.cost * 4.5} for ${businessInfo.products.selfTapeSessions.time * 4.5} minute session*`,
            `$${businessInfo.products.selfTapeSessions.cost * 6} for ${businessInfo.products.selfTapeSessions.time * 6} minute session*`,
        ],
        addOns: [
            `${businessInfo.products.selfTapeSessions.addons.coachingOrAudition.name} (+$${businessInfo.products.selfTapeSessions.addons.coachingOrAudition.cost} ${businessInfo.products.selfTapeSessions.addons.coachingOrAudition.details})`,
            `${businessInfo.products.selfTapeSessions.addons.additionalScenes.name} (+$${businessInfo.products.selfTapeSessions.addons.additionalScenes.cost} ${businessInfo.products.selfTapeSessions.addons.additionalScenes.details})`,
            `${businessInfo.products.selfTapeSessions.addons.rushEdit.name} (${businessInfo.products.selfTapeSessions.addons.rushEdit.details}/ +$${businessInfo.products.selfTapeSessions.addons.rushEdit.cost})`,
        ],
    },

}

export default productInfo
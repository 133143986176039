import portraitImage from '../util/pictures/Courtney.JPG'
import bannerImage from '../util/pictures/courtney_banner.jpeg'
import aboutBooks from '../util/pictures/books_aboutStudio.jpeg'
import studioSign from '../util/pictures/CourtneyStudioSign.jfif'
import aboutCourtneyFrontPage from '../util/pictures/CourtneyPortraitFrontPage.jpg'

const date = new Date();
const year = date.getFullYear();

export const businessInfo = {
    name: `The Courtney Studio`,
    email: `courtney@thecourtneystudio.com`,
    blurb: 'Talent Development | Consulting',
    professionalStart: 2009,
    copyrightInfo: 'COPYRIGHT © 2019 THE COURTNEY LLC. ALL RIGHTS RESERVED.',
    mission: 'The mission of The Courtney Studio is to help give Each Artist the tools and confidence to reach His/Her personal goals as a performer.',
    products: {
        oneOnOneStrategy: {
            name: `One-on-One Consulting`,
            name2: `One-on-One Strategy Sessions`,
            time: 60,
            cost: 40,
            link: 'https://thecourtneystudio.as.me/?appointmentType=2758944',
        },
        preAuditionConfidenceBoost: {
            name: `Per-Audition Confidence Boost`,
            time: 10,
            cost: 10,
        },
        selfTapeSessions: {
            name: `Self-Tape Sessions`,
            time: 10,
            cost: 10,
            cancellationFee: 15,
            addons: {
                coachingOrAudition: {
                    name: `Coaching or Audition prep`,
                    cost: 20,
                    details: `per appointment`,
                },
                additionalScenes: {
                    name: `Additional Scenes`,
                    cost: 5,
                    details: `per scene`,
                },
                rushEdit: {
                    name: `Rush Edit`,
                    cost: 15,
                    details: `sooner than 2 hours`,
                },
            },
        },
        privateActingClass: {
            name: `Private Acting Classes`,
            time: 60,
            cost: 40,
        },
        groupActingClass: {
            name: `Group Acting Classes`,
            time: 90,
            cost: 25,
        },
        initialConsultation: {
            name: `Initial Consulatation`,
            time: 15,
            cost: 0,
        },
        youthPlaywritingWorkshop: {
            name: `Youth Playwriting Workshop`,
            time: 120,
            cost: 15,
        },
        courtneyStudioPrincessAcademy: {
            name: `Courtney Studio Princess Academy`,
            time: 60,
            cost: 150,
        },
    
        

    },
};

export const pageText = {
    about: {
        pageTitle: `About Courtney`,
        portrait: portraitImage,
        frontPageImage: aboutCourtneyFrontPage,
        frontPageText: [`Courtney Beam is a North Carolina native and graduate of the University of North Carolina at Greensboro who has returned to the area after having spent over 7 years living in New York City.`,
                        `Courtney has over ${year - businessInfo.professionalStart} years of professional experience within the Entertainment Industry and has worked primarily as a Talent Agent and Talent Scout in New York. She moved back to North Carolina to pursue her passion for developing actors.`
                ],
        frontPageButtonLinks: {
            //'Learn More': `${navTemp[1].hash}${navTemp[1].link}`,
        },
        leftText: [`Courtney Beam is the founder of The Courtney Studio. With over ${year - businessInfo.professionalStart} years of multifaceted experience in the Entertainment Industry and the New York theater world, Courtney has worked with numerous actors and actresses from Broadway, Off-Broadway, television, and movies.`,
                `With a true passion for actors, teaching, and the ever-changing world of technology, Courtney has found her calling in Actor Consulting and Career Development. `,
                `Courtney graduated from the University of North Carolina at Greensboro in May of 2009 with a double major in drama and English and a concentration in theatre management. In her time as a student, Courtney spent a summer in New York City as a Marketing Intern at the Lark Play Development Center. While at the Lark, Courtney discovered a passion for new work and the playwriting process, as well as the fact that she has a knack for grass roots marketing campaigns.`,
                `In her senior year of college, Courtney was awarded an internship at Triad Stage of Greensboro, North Carolina and was soon asked to join their marketing team as a sales associate. There, she developed numerous marketing campaigns and outreach projects, as well as creating a social media presence for Triad Stage on Facebook and Twitter. Upon graduation, Courtney was chosen to be the 2009-2010 Marketing Resident at Playwrights Horizons in New York. That season included Clybourne Park by Bruce Norris, which later went on to win the Pulitzer Prize for Best Play and, after a successful run on Broadway, won the Tony Award for Best Play. The season also included the play Circle, Mirror, Transformation by Annie Baker, which won the Obie Award for Best New American Play.`,
                `While interning at Playwrights Horizons, Courtney took a job as part of the opening team of the Upper West Side Apple Store. Upon completion of her residency, Courtney remained at Apple for another year, broadening her knowledge of technology and computers while honing her teaching skills as she took on roles teaching Apple customers to use their computers and other devices. Thanks to a typical "only in New York" twist of fate, Courtney was hired as the Office Manager and Front Desk Officer at Harden Curtis Associates, a mid-size talent agency in New York.`,          
                ],
        middleImage: bannerImage,
        bottomText: [
                        `In her time with Harden Curtis Associates, Courtney worked her way from the Front Desk, to Agent Assistant, to Junior Agent, and finally to a full Talent Agent and Talent Scout, franchised by Actor’s Equity Association. Courtney has negotiated contracts for all aspects of acting from community and regional theaters to Broadway, movies and television. Courtney has taught classes about the Entertainment Industry, led discussions, sat on panels, and participated in “Agent Night” events at locations such as One on One NYC, The Growing Studio (NYC), and Paul Michael’s The Network (NYC), to name a few.`,
                        `Courtney moved back to North Carolina in 2016 to open The Courtney Studio, a dream of hers for some time. Courtney is a proud member of the North Carolina Theatre Conference, the Carolina Film Community, the Junior League of Gaston County, and the Cherryville Main Street Program. She participated in the Civic Engagement Leadership Second Cohort sponsored by Gaston Together and serves as a board member for the Cherryville Chamber of Commerce.`,
                        `Courtney has been accepted into the University of Leeds in the United Kingdom to pursue a Master of Arts degree in Arts Management and Heritage Studies, she moved to the U.K. in August of 2019.`,
        ],
    },
    consulting: {
        pageTitle: `${businessInfo.products.oneOnOneStrategy.name}`,
        frontPageText: [
            `The Courtney Studio offers, talent development and consulting services to give you the tools to become the best artist that you can be. ${businessInfo.products.oneOnOneStrategy.name2} are conducted by phone or online.`,
        ],
        frontPageImage: studioSign,
        frontPageButtonLinks: {
            //'Learn More': `${navLinks[2].hash}${navLinks[2].link}`,
            'Book Now': ``,
        },
        section1: {
            title: `The Courney Studio HAS THE ANSWER TO ALL OF YOUR ACTING QUESTIONS AND INDUSTRY CURIOSITIES.`,
            content: [
                {   subTitle: ``,
                    paragraph: [
                        `After working in the entertainment industry and working directly with actors trying to “make it”, Courtney realized that there are some major gaps in an actors education about the industry itself. Sadly, many actors move to New York or Los Angeles ready to begin their journey to stardom and they simply do not have all of the tools they need to succeed. Also, while having an agent and/or a manager is fantastic, they oftentimes simply do not have the time to truly lead a young actor through the basics or to guide a seasoned actor though the trenches when necessary. Time is the culprit - the industry moves at a fast pace!`,
                        `Courtney has also seen a distinct difference between actors who are properly prepared with their audition materials - both their lines and the world of the project for which they are auditioning, as well as their headshot and resume - and those who are only partially prepared. This can be seen, not only in their audition performance, but in their confidence as a performer.`,
                    ],
                },
        ],
            pictures: [],
            links: {},
        },
        section2: {
            title: `THIS IS WHERE COURTNEY AND THE COURTNEY STUDIO COME IN.`,
            content: [
                        {   subTitle: ``,
                            paragraph: [
                                `Courtney offers an initial Free ${businessInfo.products.initialConsultation.time} Minute Consultation and, once a lasting relationship is formed, you have the opportunity to schedule ${businessInfo.products.oneOnOneStrategy.name2} for up to ${businessInfo.products.oneOnOneStrategy.time} minutes with Courtney that is purely dedicated to you and your career as an actor.`,
                            ],
                        },
                    ],
            pictures: [],
            links: {},
        },
        section3: {
            title: `COST: $${businessInfo.products.oneOnOneStrategy.cost} FOR EACH ${businessInfo.products.oneOnOneStrategy.name2}`,
            content: [
                {   subTitle: ``,
                    paragraph: [
                        `Courtney will guide you in any area of the industry you would like to explore, from a very basic introduction to acting, choosing the right MFA program for you, auditioning, and the entertainment industry, to much more advanced discussions on career advancement, marketing, and navigating pilot season. For actors who are local to the Charlotte, North Carolina area, Courtney also offers Self-Tape Audition services!`,
                        `Another type of session offered is specifically geared toward parents of children who are interested in pursuing a career in show business. These discussions will help point you in the right direction for your child or simply share advice on how to be a supportive parent to your child as s/he embarks upon an artist's life.`,
                    ],
                },
                    ],
            pictures: [],
            links: {},
        },
        section4: {
            title: `POTENTIAL DISCUSSION TOPICS INCLUDE:`,
            content: [
                {   subTitle: `BEGINNER ACTOR EXAMPLES:`,
                    paragraph: [
                        `For Parents: My child wants to be an actor, what do I do?`,
                        `Can I make a living with a major in theatre?`,
                        `BFA/MFA Program audition preparation`,
                    ],
                },
                {   subTitle: `INTERMEDIATE ACTOR EXAMPLES:`,
                    paragraph: [
                        `What is my “Type”?`,
                        `Help choosing the right headshot`,
                        `Are you ready to move to New York?`,
                    ],
                },
                {   subTitle: `ADVANCED ACTOR EXAMPLES:`,
                    paragraph: [
                        `Audition preparation for TV vs Theatre`,
                        `My audition wardrobe`,
                        `What is the next step in my career?`,
                        `...and so many more!`,
                    ],
                },
        ],
            pictures: [aboutBooks],
            links: [],
        },
        section5: {
            title: `Ways to Connect:`,
            content: [
                {   subTitle: `VIDEO CONFERENCING`,
                    paragraph: [
                        `In New York? LA? Chicago? No problem!`,
                        `In this ever-changing world of technology, speaking face-to-face over large distances has never been easier! Talk with Courtney one-to-one and face-to-face in the comfort of your own home using one of these services:`,
                        `Skype, Google Hangout, and Apple FaceTime`,
                    ],
                    disclaimer: [`*If you know of other video conferencing options, we are always open to trying new things! Let us know!`],
                },
                {   subTitle: `BY PHONE`,
                    paragraph: [
                        `While in our industry, face-to-face contact is ideal, Courtney is absolutely available for consultations over the phone. This method is also great for pre-audition pep talks!`,
                    ],
                },
        ],
            pictures: [],
            links: [],
        }

    },
    appointments : {
        pageTitle: `Appointments`,
        topText: `Book an Appointment with Courtney!`,
        middleText: `Use the form below to book any of our available services with`,
        bottomText: `The Courtney Studio`,
    },
    FAQ: {
        pageTitle: 'FAQ',
    },
}
import React from 'react'
import Footer from '../components/Footer/Footer'
import FAQ from '../components/FAQ/FAQ'
import NavBar from '../components/Nav/NavBar'
import { useViewport } from '../components/ViewPort/ViewportProvider'
import MobileNavBar from '../components/Nav/MobileNavBar'
import styleSheet from '../styles/styleSheet'

const FaqPage = () => {
    const { width } = useViewport();
    const breakpoint = 567;
    
    return (
        width < breakpoint ? 
        <div style={styleSheet.mobile.pageSetup} >
            <MobileNavBar thisPage="FAQ" />
            <FAQ device="mobile" />
            <Footer />
        </div>
        :
        <div style={styleSheet.pageSetup} >
            <NavBar thisPage="FAQ" />
            <FAQ />
            <Footer />
        </div>
    )
}

export default FaqPage
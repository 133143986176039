import React from 'react'
import styleSheet from '../../styles/styleSheet';
import { businessInfo } from './../../Information/pageText';
import { socialLinks } from '../../Information/pageLinks';

const Footer = () => {
    function onMouseIn(e) {
        e.target.style.color = 'rgba(82, 82, 82, 78)';
    }
    function onMouseOut(e) {
            e.target.style.color = 'rgba(82, 82, 82, .78)';
    }
    function footerIn(e) {
        e.target.style.color = 'rgba(82, 82, 82, .78)';
    }
    function footerOut(e) {
            e.target.style.color = '#000';
    }
    return (
        <div>
            <div stlye={styleSheet.footer.h2Container}>
            <h2 style={styleSheet.footer.h2}>{businessInfo.name}</h2>
            </div>

            
            <div style={styleSheet.footer.faContainer}>
            {socialLinks.map(item => {
              return <a 
                style={styleSheet.footer.fa}
                href={item.link}
                className={`${item.objectClass} ${item.iconClass}`}
                onMouseEnter={footerIn} onMouseLeave={footerOut}><span style={styleSheet.footer.span}></span></a>;
            })}
            </div>

            <div style={styleSheet.footer.container}>
            <a onMouseEnter={onMouseIn} onMouseLeave={onMouseOut} style={styleSheet.footer.a} href='/#/contact'>Contact</a>
            <a onMouseEnter={onMouseIn} onMouseLeave={onMouseOut} style={styleSheet.footer.a} href='/#/faq'>FAQ</a>
            {/*<a onMouseEnter={onMouseIn} onMouseLeave={onMouseOut} style={styleSheet.footer.a} href='/#/blog'>Blog</a>*/}
            </div>

            <div className='copyright'>
                <p style={styleSheet.footer.copyrightInfo} >{businessInfo.copyrightInfo}</p>
            </div>

        </div>
    )
}

export default Footer

import React from 'react'
import NavBar from '../Nav/NavBar'
import PageCard from '../PageCard/PageCard'
import Mission from '../Mission/Mission';
import styleSheet from '../../styles/styleSheet';
import {navLinks} from '../../Information/pageLinks'
import {businessInfo, pageText} from '../../Information/pageText'
import Blurb from '../Blurb/Blurb';
import PictureBanner from './PictureBanner/PictureBanner';
import BookNow from '../Booking/BookNow';
import MobileNavBar from '../Nav/MobileNavBar';


const Home = (props) => {
    return (
        <div style={props.device === "mobile" ? styleSheet.mobile.pageSetup : styleSheet.pageSetup} >
            {props.device === "mobile" ? <MobileNavBar thisPage="Home"/> : <NavBar thisPage="Home"/>}
            <PictureBanner device={props.device}/>
            <Blurb device={props.device}/>
            <div className='row'>
            <PageCard page={pageText.consulting} link={navLinks[2]} order='1' link2={businessInfo.products.oneOnOneStrategy} device={props.device}/>
            <PageCard page={pageText.about} link={navLinks[1]} order='2'device={props.device}/>
            </div>
            <Mission device={props.device}/>
            <BookNow device={props.device}/>
        </div>
    )
}

export default Home

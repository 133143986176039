import React from 'react'
import { pageText } from '../../Information/pageText'
import styleSheet from '../../styles/styleSheet'

const AboutLayout = (props) => {
    return (
        <div>
            <h1 className="text-center" style={styleSheet.titles} >{pageText.about.pageTitle}</h1>
            
            <div className="row">

                <div className="col-md-6 col-12 order-2 order-md-1" style={styleSheet.AboutPage.textContainerLeft} >
                    {pageText.about.leftText.map(paragraph => {
                        return <p style={styleSheet.AboutPage.text} className="text-justify">{paragraph}</p>
                    })}</div>
                
            <img src={pageText.about.portrait} alt="Courtney Beam" className="col-12 col-md-6 align-self-baseline order-1 order-md-2" style={styleSheet.AboutPage.portrait} />
                
            </div>


            <img className="mx-auto d-block" src={pageText.about.middleImage} alt="Courtney at various broadway premieres." style={styleSheet.AboutPage.banner} />
                
                <div className="" style={styleSheet.AboutPage.textContainer} >
                    {pageText.about.bottomText.map(paragraph => {
                        return <p style={styleSheet.AboutPage.text} className="text-justify">{paragraph}</p>
                    })}
                </div>

        </div>
    )
}

export default AboutLayout

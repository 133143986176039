import React from 'react'
import styleSheet from '../../../styles/styleSheet';
import pinkLogo from '../../../util/pictures/CourtneyStudioLogoPinkMargins1.jpg'

const PictureBanner = (props) => {
    return (
        props.device === "mobile" ? 
        <div style={styleSheet.mobile.pictureBanner}>
            <img style={styleSheet.mobile.pictureBanner.img} src={pinkLogo} alt="The Courtney Strudio" />
            
        </div>
        :
        <div style={styleSheet.pictureBanner}>
            
        </div>
    )
}

export default PictureBanner

import React from 'react'
import Home from '../components/Home/Home'
import Footer from '../components/Footer/Footer'
import { useViewport } from '../components/ViewPort/ViewportProvider'

const HomePage = () => {
    const { width } = useViewport();
    const breakpoint = 567;

    return (
        width < breakpoint ? 
        <div>
            <Home device="mobile"/>
            <Footer />
        </div> 
        : 
        <div>
            <Home device="desktop" />
            <Footer />
        </div> 
    )
}

export default HomePage

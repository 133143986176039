import React from 'react'
import styleSheet from '../../styles/styleSheet'

const BookNow = () => {
    function onMouseIn(e) {
        e.target.style.background = 'black';
        e.target.style.color = 'white';
    }
    function onMouseOut(e) {
            e.target.style.background = 'white';
            e.target.style.color = 'black';
    }
    return (
        <div>
            <h2 style={styleSheet.bookNow.h2}>ONLINE CONSULTING AVAILABLE!</h2>
            <h3 style={styleSheet.bookNow.h3}>THE COURTNEY STUDIO OFFERS GLOBAL </h3>
            <h3 style={styleSheet.bookNow.h3}>ONLINE CONSULTATIONS! </h3>
            <h3 style={styleSheet.bookNow.h3}>BOOK TODAY!</h3>
            <p style={styleSheet.bookNow.p}>Click below to book a session!</p>
            
            <div style={styleSheet.bookNow.div}>
            <a onMouseEnter={onMouseIn} onMouseLeave={onMouseOut} href='https://thecourtneystudio.as.me/' className="btn text-center" style={styleSheet.bookNow.button}>Book</a>
            </div>
        
        </div>
    )
}

export default BookNow

import React from 'react'
import { pageText } from '../../Information/pageText'
import styleSheet from '../../styles/styleSheet'

const ConsultingLayout = (props) => {
    return (
        <div>
            <h1 style={styleSheet.titles}>{pageText.consulting.pageTitle}</h1>

            <div className="section1">
            <h2 style={styleSheet.h2}>{pageText.consulting.section1.title}</h2>
            {pageText.consulting.section1.content.map(object => {
                return (
                    object.subTitle ? 
                    <div key={object.subTitle}>
                        <h3 style={styleSheet.h3}>{object.subTitle}</h3>
                        {object.paragraph.map(paragraph => {
                            return <p style={styleSheet.p} key={paragraph}>{paragraph}</p>
                        })}
                    </div> 
                    : 
                    <div>
                        {object.paragraph.map(paragraph => {
                            return <p style={styleSheet.p} key={paragraph}>{paragraph}</p>
                        })}
                    </div>
                )})}
            </div>

            <div className="section2">
            <h2 style={styleSheet.h2}>{pageText.consulting.section2.title}</h2>
            {pageText.consulting.section2.content.map(object => {
                return (
                    object.subTitle ? 
                    <div key={object.subTitle}>
                        <h3 style={styleSheet.h3}>{object.subTitle}</h3>
                        {object.paragraph.map(paragraph => {
                            return <p style={styleSheet.p} key={paragraph}>{paragraph}</p>
                        })}
                    </div> 
                    : 
                    <div>
                        {object.paragraph.map(paragraph => {
                            return <p style={styleSheet.p} key={paragraph}>{paragraph}</p>
                        })}
                    </div>
                )})}
            </div>

            <div className="section3">
            <h2 style={styleSheet.h2}>{pageText.consulting.section3.title}</h2>
            {pageText.consulting.section3.content.map(object => {
                return (
                    object.subTitle ? 
                    <div key={object.subTitle}>
                        <h3 style={styleSheet.h3}>{object.subTitle}</h3>
                        {object.paragraph.map(paragraph => {
                            return <p style={styleSheet.p} key={paragraph}>{paragraph}</p>
                        })}
                    </div> 
                    : 
                    <div>
                        {object.paragraph.map(paragraph => {
                            return <p style={styleSheet.p} key={paragraph}>{paragraph}</p>
                        })}
                    </div>
                )})}
            </div>

            <div className="section4">
            <h2 style={styleSheet.h2}>{pageText.consulting.section4.title}</h2>
            <div className="row">
                
                <img className="col-12 col-md-6 order-1 order-md-2" style={styleSheet.constulting.booksImage} src={pageText.consulting.section4.pictures[0]} alt="A bookshelf from The Courtney Studio" />
            
                <div className="col-md-6 col-12 order-2 order-md-1">
                {pageText.consulting.section4.content.map(object => {
                    return (
                        object.subTitle ? 
                        <div key={object.subTitle}>
                            <h3 style={styleSheet.h3}>{object.subTitle}</h3>
                            {object.paragraph.map(paragraph => {
                                return <p style={styleSheet.constulting.section4Custom} key={paragraph}>{paragraph}</p>
                            })}
                        </div> 
                        : 
                        <div>
                            {object.paragraph.map(paragraph => {
                                return <p style={styleSheet.constulting.section4Custom} key={paragraph}>{paragraph}</p>
                            })}
                        </div>
                    )})}
                </div>
                    
            </div>
            
            
            </div>

            <div className="section5">
            <h2 style={styleSheet.h2}>{pageText.consulting.section5.title}</h2>
            {pageText.consulting.section5.content.map(object => {
                return (
                    object.subTitle ? 
                    <div key={object.subTitle}>
                        <h3 style={styleSheet.h3}>{object.subTitle}</h3>
                        {object.paragraph.map(paragraph => {
                            return <p style={styleSheet.p} key={paragraph}>{paragraph}</p>
                        })}
                    </div> 
                    : 
                    <div>
                        {object.paragraph.map(paragraph => {
                            return <p style={styleSheet.p} key={paragraph}>{paragraph}</p>
                        })}
                    </div>
                )})}
            </div>

        </div>
    )
}

export default ConsultingLayout

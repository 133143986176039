//import Index from "../Pages/index"
import About from "../Pages/about"
import Consulting from "../Pages/consulting";
import Appointments from "../Pages/appointments";
import HomePage from "../Pages/home";
import FaqPage from "../Pages/faq";

const hash = '/#'

const socialMediaLinks = {
    Twitter: "https://twitter.com/StudioCourtney",
    Pinterest: "https://www.pinterest.co.uk/thecourtney",
    LinkedIn: "https://www.linkedin.com/in/courtneybeam",
    Facebook: "https://www.facebook.com/thecourtneystudio",
    Instagram: "https://www.instagram.com/thecourtneystudio",
    YouTube: "https://www.youtube.com/channel/UCEobG_Dphgry62NYsB3pg8Q",
}

export const navLinks = [
    {
        name: 'Home',
        link: '/home',
        objectClass: 'Home',
        pageName: HomePage,
        hash: hash,
    },{
        name: 'About Courtney',
        link: '/about',
        objectClass: '',
        pageName: About,
        hash: hash,
    },{
        name: 'Consulting',
        link: '/consulting',
        objectClass: 'consulting',
        pageName: Consulting,
        hash: hash,
    },{
        name: 'Appointments',
        link: '/appointments',
        objectClass: 'appointments',
        pageName: Appointments,
        hash: hash,
    },/*{
        name: 'Portfolio',
        link: '/',
        objectClass: '',
        pageName: '#',
        hash: hash,
    },{
        name: 'Contact',
        link: '/',
        objectClass: '',
        pageName: '#',
        hash: hash,
    },*/

    
];

export const socialLinks = [
        {
            site: 'Email',
            iconClass: 'fa-envelope',
            objectClass: 'fa',
            link: `mailto:courtney@thecourtneystudio.com`,
            logoColor: '#00b489',
        },
        {
            site: 'Twitter',
            iconClass: 'fa-twitter',
            objectClass: 'fa',
            link: socialMediaLinks.Twitter,
            logoColor: '#55ACEE',
        },{
            site: 'Pinterest',
            iconClass: 'fa-pinterest',
            objectClass: 'fa',
            link: socialMediaLinks.Pinterest,
            logoColor: '#cb2027',
        },{
            site: 'Facebook',
            iconClass: 'fa-facebook',
            objectClass: 'fa',
            link: socialMediaLinks.Facebook,
            logoColor: '#3B5998',
        },{
            site: 'LinkedIn',
            iconClass: 'fa-linkedin',
            objectClass: 'fa',
            link: socialMediaLinks.LinkedIn,
            logoColor: '#ff5700',
        },{    
            site: 'Instagram',
            iconClass: 'fa-instagram',
            objectClass: 'fa',
            link: socialMediaLinks.Instagram,
            logoColor: '#125688',
        },{
            site: 'YouTube',
            iconClass: 'fa-youtube',
            objectClass: 'fa',
            link: socialMediaLinks.YouTube,
            logoColor: '#bb0000',
        },/*{
            site: 'GitHub',
            iconClass: 'fa-github',
            objectClass: 'fa',
            link: `https://github.com/MopishHobgoblin`,
            logoColor: '#ffffff',
        },*/
];
export const otherPages = [
    {
        name: 'FAQ',
        link: '/faq',
        objectClass: 'faq',
        pageName: FaqPage,
        hash: hash,
    },
];
import React from 'react'
import { navLinks } from '../../Information/pageLinks'
import styleSheet from '../../styles/styleSheet'

import '../../util/styles/AvailableFonts.css'

const MobileNavBar = (props) => {
    return (
        <div className="">
                <nav className="navbar navbar-expand-lg navbar-light" style={styleSheet.mobile.NavBar}>
                
                <div className="container-fluid">
                <button className="navbar-toggler col-3" style={{padding: "0px", margin:"0px"}} type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <a className="navbar-brand col-8" href="/#/"><div style={styleSheet.mobile.NavBrand}>The Courtney Studio</div></a>
                
                </div>
                
            <div className=""></div>
                <div className="">
                <div className="">
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup" >
                <div className="navbar-nav me-auto mb-2 mb-lg-0" style={styleSheet.NavLinks}>
                {navLinks.map(item => {
                       return (props.thisPage===item.name ?  <a className="nav-link active" href={`${item.hash}${item.link}`}>{item.name}<span className="sr-only">(current)</span></a> : <a className="nav-link" href={`${item.hash}${item.link}`}>{item.name}</a>);
                    })}
            </div></div></div></div>
            </nav>
            
        </div>
    )
}

export default MobileNavBar

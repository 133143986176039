import React from 'react'
import styleSheet from './../../styles/styleSheet';
import { businessInfo } from './../../Information/pageText';

const Mission =(props) => {
    return (
        <div style={props.device === "mobile" ? styleSheet.mobile.MissionComponent.background : styleSheet.MissionComponent.background}>
            <h3 style={props.device === "mobile" ? styleSheet.mobile.MissionComponent.h3 : styleSheet.MissionComponent.h3} >Our Mission</h3>
            <h1 style={props.device === "mobile" ? styleSheet.mobile.MissionComponent.h1: styleSheet.MissionComponent.h1}>{businessInfo.mission}</h1>
        </div>
    )
}



export default Mission
